import React, { useEffect, useState } from "react";
import {
  OFFER_TYPES,
  OFFER_DISPLAY_NAMES,
  PLATFORMS,
  PRODUCT_MODELS,
  USER_ROLE,
  K12_TYPE,
  REDIRECTIONS,
} from "../../../config";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import "./offers.scss";
import { updateShowDDA } from "../../pages/pdp/productSlice";
import { OfferTabs } from "./offerTabs/OfferTabs";
import { StudentOfferTab } from "./offerTabs/studentOfferTab/StudentOfferTab";
import { InstitutionalOfferTab } from "./offerTabs/institutionalOfferTab/InstitutionalOfferTab";
import { getCurrencySymbol } from "../../util/offerUtils";
import { ReactComponent as ContactSavvasIcon } from "../../images/contact-savvas-icon.svg";
import ReactGA from "react-ga4";

export function Offers() {
  const userRole = useSelector((state) => state.user.userRole);
  const dispatch = useDispatch();
  const productOffers = useSelector((state) => state.product.productOffers);
  const userData = useSelector((state) => state.user.userData);
  const currentInstitute = useSelector((state) => state.user.currentInstitute);
  const productData = useSelector((state) => state.product.productData);
  const [directBillOffers, setDirectBillOffers] = useState([]);
  const [seatLicenseOffers, setSeatLicenseOffers] = useState([]);
  const [accessCodeOffers, setAccessCodeOffers] = useState([]);
  const [instantAccessOffers, setInstantAccessOffers] = useState([]);
  const [tempAccessOffers, setTempAccessOffers] = useState([]);
  const showDDA = useSelector((state) => state.product.showDDA);
  const productAvailableToBuyOffers = useSelector(
    (state) => state.product.productAvailableToBuyOffers
  );

  useEffect(() => {
    if (
      productOffers &&
      productOffers.offers &&
      productOffers.offers.length > 0
    ) {
      const tempDirectBillOffers = _.filter(
        productOffers.offers,
        function (offer) {
          if (offer.offerType && offer.offerType.offerTypeCode) {
            return offer.offerType.offerTypeCode === OFFER_TYPES.DIRECT_BILL;
          }
        }
      );
      setDirectBillOffers(tempDirectBillOffers);
      const tempSeatLicenseOffers = _.filter(
        productOffers.offers,
        function (offer) {
          if (offer.offerType && offer.offerType.offerTypeCode) {
            return offer.offerType.offerTypeCode === OFFER_TYPES.SEAT_LICENSE;
          }
        }
      );
      setSeatLicenseOffers(tempSeatLicenseOffers);
      const tempAccessCodeOffers = _.filter(
        productOffers.offers,
        function (offer) {
          if (offer.offerType && offer.offerType.definition) {
            if (
              productData.productPlatform === PLATFORMS.ETEXT &&
              productData.productModel.name ===
                PRODUCT_MODELS.CUSTOM_COLLECTIONS
            ) {
              return (
                offer.offerType.offerTypeCode === OFFER_TYPES.ACCESS_CARD &&
                offer.orgId === currentInstitute.organization.orgId
              );
            } else {
              return offer.offerType.offerTypeCode === OFFER_TYPES.ACCESS_CARD;
            }
          }
        }
      );
      setAccessCodeOffers(tempAccessCodeOffers);
      const tempInstantAccessOffers = _.filter(
        productOffers.offers,
        function (offer) {
          if (offer.offerType && offer.offerType.definition) {
            if (
              productData.productPlatform === PLATFORMS.ETEXT &&
              productData.productModel.name ===
                PRODUCT_MODELS.CUSTOM_COLLECTIONS
            ) {
              return (
                offer.offerType.offerTypeCode === OFFER_TYPES.INSTANT_ACCESS &&
                offer.orgId === currentInstitute.organization.orgId
              );
            } else {
              return (
                offer.offerType.offerTypeCode === OFFER_TYPES.INSTANT_ACCESS
              );
            }
          }
        }
      );
      setInstantAccessOffers(tempInstantAccessOffers);
      const tempTempAccessOffers = _.filter(
        productOffers.offers,
        function (offer) {
          if (
            productData.productPlatform === PLATFORMS.ETEXT &&
            productData.productModel.name === PRODUCT_MODELS.CUSTOM_COLLECTIONS
          ) {
            return (
              offer.offerType.offerTypeCode === OFFER_TYPES.FREE_TRIAL &&
              offer.orgId === currentInstitute.organization.orgId
            );
          } else {
            return offer.offerType.offerTypeCode === OFFER_TYPES.FREE_TRIAL;
          }
        }
      );
      setTempAccessOffers(tempTempAccessOffers);

      const tempShowDDA =
        tempDirectBillOffers &&
        tempDirectBillOffers.length > 0 &&
        tempAccessCodeOffers &&
        tempAccessCodeOffers.length > 0 &&
        productOffers.offerType === "national" &&
        productOffers.comingSoon === false;

      dispatch(updateShowDDA(tempShowDDA));
    }
  }, [productOffers]);

  const contactSavvasHandler = () => {
    ReactGA.event({
      category: "CTA",
      action: "click CTA button",
      label: "Contact Savvas rep",
    });
    window.open(REDIRECTIONS.CONTACT_SAVVAS);    
  }

  const getContactSavvas = () => {
    return (
      <div className="custom-padding">
        <p className="des-text contact-savvas-text mb-0">
          Contact Savvas to ask questions, confirm institutional pricing, and
          purchase
        </p>
        <div className="pdp-btn-container mt-4">
          <button
            className="custom-btn fill-black-btn"
            onClick={() => contactSavvasHandler()}>
            Contact Savvas rep
            <span className="ml-4">
              <ContactSavvasIcon />
            </span>
            <span className="sr-only" id="contactSavvas">
              Opens in a new tab
            </span>
          </button>
        </div>
      </div>
    );
  };

  const getOfferInfo = (offers, offerTypeCode) => {
    return (
      <React.Fragment>
        <div className="hr-line"></div>
        <div className="offer-opt-info">
          <p className="offer-opt-info-title">
            {OFFER_DISPLAY_NAMES[offerTypeCode]}
          </p>
          { (offerTypeCode === OFFER_TYPES.FREE_TRIAL || offerTypeCode === OFFER_TYPES.INSTANT_ACCESS || offerTypeCode === OFFER_TYPES.DIRECT_BILL) && offers[0].description && ( 
            <p className="offer-info">{offers[0].description}</p>
          )}
          { offerTypeCode === OFFER_TYPES.ACCESS_CARD && (
            <p className="offer-info">
              Buy access card per student, per course.
            </p>
          )}
          {offers.map((offer, index) => (
            <React.Fragment>             
              {offer.offerType &&
                offer.offerType.definition &&
                offer.offerType.offerTypeCode !== OFFER_TYPES.FREE_TRIAL &&
                offer.offerType.definition.subscriptionDuration && (
                  <div className="d-flex align-items-center justify-content-between available-offer">
                    <p className="offer-info">
                    {`${offer.offerType.definition.subscriptionDuration} ${
                      offer.offerType.definition.subscriptionDuration > 1
                        ? "days"
                        : "day"
                    } Subscription`}{" "}
                    </p>
                    {offer.offerType.definition.currency && (
                      <p className="offer-currency-txt">
                        {`${getCurrencySymbol(
                          offer.offerType.definition.currency.toUpperCase()
                        )}${offer.offerType.definition.price} ${
                          offer.offerType.definition.currency
                        }`}
                        {offer.offerType.offerTypeCode ===
                          OFFER_TYPES.ACCESS_CARD && <sup>*</sup>}
                      </p>
                    )}
                  
                  </div>
                )}
              {offer.offerType &&
                offer.offerType.definition &&
                offer.offerType.offerTypeCode === OFFER_TYPES.ACCESS_CARD &&
                offer.description && (
                  <p className="offer-info">{offer.description}</p>
                )}

              {productData &&
                offer.offerType &&
                offer.offerType.definition &&
                offer.offerType.definition.isbn &&
                offer.offerType.definition.isbn.isbn13 && (
                  <p className="offer-info isbn"><b>ISBN</b> {offer.offerType.definition.isbn.isbn13}</p>
                )}
            </React.Fragment>
          ))}
          {offers[0].offerType &&
            offers[0].offerType.offerTypeCode &&
            offers[0].offerType.offerTypeCode === OFFER_TYPES.ACCESS_CARD && (
              <React.Fragment>
                <p className="offer-info book-price-vary">
                  <sup>*</sup>Bookstore pricing may vary
                </p>
              </React.Fragment>
            )}
        </div>
      </React.Fragment>
    );
  };

  const getOffers = () => {
    if (
      productOffers.offers &&
      productOffers.offers.length > 0 &&
      userData &&
      userData.id &&
      (userData.hasInstitutions ||
        userData.isStudent ||
        userData.isStudentInstructer)
    ) {
      if (!productOffers.comingSoon) {
        if (productOffers.offerType.toLowerCase() === "institutional") {
          return (
            <div className="custom-padding">
              <div className="hr-line"></div>
                <div className="access-container">
                  {(directBillOffers.length > 0 ||
                    accessCodeOffers.length > 0 ||
                    instantAccessOffers.length > 0 ||
                    tempAccessOffers.length > 0) && (
                    <h3 className="opt-title my-0">Student access options</h3>
                  )}
      
                  {directBillOffers &&
                    directBillOffers.length > 0 &&
                    getOfferInfo([directBillOffers[0]], OFFER_TYPES.DIRECT_BILL)}
                  {instantAccessOffers &&
                    instantAccessOffers.length > 0 &&
                    getOfferInfo(instantAccessOffers, OFFER_TYPES.INSTANT_ACCESS)}
                  {accessCodeOffers &&
                    accessCodeOffers.length > 0 &&
                    getOfferInfo(accessCodeOffers, OFFER_TYPES.ACCESS_CARD)}
                  {tempAccessOffers &&
                    tempAccessOffers.length > 0 &&
                    getOfferInfo(tempAccessOffers, OFFER_TYPES.FREE_TRIAL)}
                    
                </div>
                
            </div>
          );
        } else if (
          productOffers.offerType.toLowerCase() === "national" &&
          directBillOffers.length > 0
        ) {
          return (
            <div className="pdp-rightside-wr national">
              {directBillOffers.length > 0 && (
                <h3 className="opt-title my-0">Student access options</h3>
              )}
              {directBillOffers &&
                directBillOffers.length > 0 &&
                getOfferInfo([directBillOffers[0]], OFFER_TYPES.DIRECT_BILL)}
            </div>
          );
        } else if (
          productOffers.offerType.toLowerCase() === "national" &&
          directBillOffers.length === 0
        ) {
          return (
            <div className="pdp-rightside-wr national">
              {(accessCodeOffers.length > 0 ||
                instantAccessOffers.length > 0 ||
                tempAccessOffers.length > 0) && (
                <h3 className="opt-title my-0">Student access options</h3>
              )}
              {instantAccessOffers &&
                instantAccessOffers.length > 0 &&
                getOfferInfo(instantAccessOffers, OFFER_TYPES.INSTANT_ACCESS)}
              {accessCodeOffers &&
                accessCodeOffers.length > 0 &&
                getOfferInfo(accessCodeOffers, OFFER_TYPES.ACCESS_CARD)}
              {tempAccessOffers &&
                tempAccessOffers.length > 0 &&
                getOfferInfo(tempAccessOffers, OFFER_TYPES.FREE_TRIAL)}
            </div>
          );
        }
      } else if (
        instantAccessOffers.length > 0 ||
        accessCodeOffers.length > 0 ||
        tempAccessOffers.length > 0 ||
        (directBillOffers.length > 0 &&
          directBillOffers[0].orgId === currentInstitute.organization.orgId) ||
        (seatLicenseOffers.length > 0 &&
          seatLicenseOffers[0].orgId === currentInstitute.organization.orgId)
      ) {
        return (
          <div className="pdp-rightside-wr available-soon">
            <p className="access-info check-later-txt mb-0">
              Please check back later.
            </p>
            <h3 className="opt-title my-0">Student access options</h3>
            <div className="offer-opt-info">
              <p className="offer-info coming-soon-offer-txt">
                Offers for this product will be available soon.
              </p>
            </div>
          </div>
        );
      }
    }
  };

  const tabToDisplay = () => {
    const hasStudentOffers =
      ((tempAccessOffers && tempAccessOffers.length > 0) ||
        (instantAccessOffers && instantAccessOffers.length > 0)) &&
      productOffers &&
      !productOffers.comingSoon;
    const hasInstitutionalOffers =
      (accessCodeOffers &&
        accessCodeOffers.length > 0 &&
        productOffers &&
        !productOffers.comingSoon) ||
      (productAvailableToBuyOffers &&
        productAvailableToBuyOffers.availableTobuy &&
        productAvailableToBuyOffers.availableTobuy.length > 0);

    if (hasInstitutionalOffers && !hasStudentOffers) {
      return (
        <div className="offer-separator">
          <h3 className="opt-title my-0 offer-bottom-separtor">
            Institutional purchase options
          </h3>
          <InstitutionalOfferTab accessCodeOffers={accessCodeOffers} />
        </div>
      );
    } else if (hasStudentOffers && !hasInstitutionalOffers) {
      return (
        <div className="offer-separator">
          <h3 className="opt-title my-0 offer-bottom-separtor">
            Student purchase options
          </h3>
          <StudentOfferTab
            instantAccessOffers={instantAccessOffers}
            tempAccessOffers={tempAccessOffers}
          />
        </div>
      );
    } else if (hasInstitutionalOffers && hasStudentOffers) {
      return (
        <OfferTabs
          accessCodeOffers={accessCodeOffers}
          tempAccessOffers={tempAccessOffers}
          instantAccessOffers={instantAccessOffers}
        />
      );
    } else if ( //TODO: Refactor this. productOffers, hasInstitutionalOffers, hasStudentOffers contains overlapping offer types.
      !hasInstitutionalOffers &&
      !hasStudentOffers &&   
      !(seatLicenseOffers.length > 0 && accessCodeOffers.length === 0 && tempAccessOffers.length === 0 && instantAccessOffers.length === 0) &&  
      productOffers &&
      productOffers.comingSoon
    ) {
      return (
        <div className="offer-separator">
          <div className="custom-padding">
            <h3 className="opt-title my-0 coming-soon-k12">
              Student purchase options
            </h3>
            <p className="coming-soon-text-k12 mb-0 mt-2">
              Offers for this product will be available soon. Please check back
              later.
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      {userRole &&
        (userRole === USER_ROLE.HIGHER_ED_INSTRUCTOR ||
          userRole === USER_ROLE.STUDENT) &&
        productOffers &&
        productOffers.offers &&
        !showDDA &&
        getOffers()}
      {/* Contact Savvas button should appear only if Available to buy offers OR Access Code offers available. */}
      {userRole &&
        userRole === USER_ROLE.K12_BUYER &&
        !showDDA &&
        productData &&
        productData.k12 &&
        productData.k12 === K12_TYPE.AVAILABLE_FOR_LICENSE &&
        ((accessCodeOffers &&
          productOffers &&
          !productOffers.comingSoon &&
          accessCodeOffers.length > 0) ||
          productAvailableToBuyOffers) &&
        getContactSavvas()}
      {/* Display tabs based on the offer availability */}
      {userRole &&
        userRole === USER_ROLE.K12_BUYER &&
        productData &&
        (productOffers || productAvailableToBuyOffers) &&
        tabToDisplay()}
    </React.Fragment>
  );
}
