export const AUTO_COMPLETE_LENGTH = 3;
export const AUTO_COMPLETE_AUTHOR_LENGTH = 55;
export const AUTO_COMPLETE_TITLE_LENGTH = 50;
export const PLATFORMS = {
  ETEXT: "ETEXT",
  REVEL: "REVEL",
  COLLECTION: "COLLECTION",
  LEARNING_APPLICATIONS: "LEARNING_APPLICATIONS",
  GLP: "GLP",
  PRINT: "PRINT",
};
export const PDP_AUTHORS_LENGTH = 50;
export const PRODUCT_MODELS = {
  REVEL: "REVEL",
  SUMATRA: "SUMATRA",
  REVEL_CITE: "REVEL_CITE",
  ETEXT2_PXE: "ETEXT2_PXE",
  ETEXT2_CITE: "ETEXT2_CITE",
  ETEXT1_PDF: "ETEXT1_PDF",
  ETEXT_EPUB_STANDARD: "ETEXT_EPUB_STANDARD",
  ETEXT_EPUB_BRONTE: "ETEXT_EPUB_BRONTE",
  CUSTOM_COLLECTIONS: "CUSTOM_COLLECTIONS",
  COLLECTION: "COLLECTION",
  LEARNING_CATALYTICS: "LEARNING_CATALYTICS",
  MEDIA_SHARE: "MEDIA_SHARE",
  PEARSON_WRITER: "PEARSON_WRITER",
  OZ_CITE: "OZ_CITE",
  SUMATRA_CITE: "SUMATRA_CITE",
};
export const PRODUCT_STAGE = {
  ACTIVE: "ACTIVE",
  FULL: "FULL", //this is a product type
  REVIEW: "REVIEW",
  SAMPLE: "SAMPLE", //this is a product type
};
export const OVERVIEW_DESCRIPTION_LENGTH = 600;
export const AUTHOR_DESCRIPTION_LENGTH = 600;
export const FEATURE_DESCRIPTION_LENGTH = 600;
export const TOC_DESCRIPTION_LENGTH = 600;
export const MODULE_LIST_LENGTH = 7;
export const TOC_LIST_LENGTH = 4;
export const INSTITUTE_DESCRIPTION_LENGTH = 16;
export const PAGES = {
  SERP: "SERP",
  PDP: "PDP",
  HOME: "HOME",
  GET_START: "GET_START",
  NOT_FOUND: "NOT_FOUND",
};
export const PRODUCT_TYPES = {
  FULL: "FULL",
  SAMPLE: "SAMPLE",
  BETA: "BETA",
};
export const PRODUCT_OVERLAYERS = {
  CLASS_TEST: "CLASS_TEST",
  SAMPLE: "SAMPLE",
  COMING_SOON: "COMING_SOON",
};
export const OFFER_TYPES = {
  INSTANT_ACCESS: "INSTANT_ACCESS",
  DIRECT_BILL: "DIRECT_BILL",
  ACCESS_CARD: "ACCESS_CARD",
  FREE_TRIAL: "FREE_TRIAL",
  SEAT_LICENSE: "SEAT_LICENSE",
};
export const OFFER_DISPLAY_NAMES = {
  INSTANT_ACCESS: "Instant access",
  DIRECT_BILL: "Institutional direct access",
  ACCESS_CARD: "Access card",
  FREE_TRIAL: "Temporary access",
};
export const SERP_PLATFORMS = {
  ALL: {
    displayValue: "",
    type: "all",
  },
  ETEXT: {
    displayValue: "Pearson eText",
    type: "etexts",
    // platformIcon: "etext",
  },
  REVEL: {
    displayValue: "REVEL",
    type: "courses",
    // platformIcon: "revel",
  },
  CUSTOM: {
    displayValue: "Custom",
    type: "custom",
    // platformIcon: "custom",
  },
  APPLICATION: {
    displayValue: "Application",
    type: "applications",
    // platformIcon: "learning_applications",
  },
  COLLECTION: {
    displayValue: "Collections",
    type: "etexts_custom_collection",
    // platformIcon: "collection",
  },
  REVIEWER: {
    displayValue: "Reviewer",
    type: "reviewer",
    // platformIcon: "reviewer",
  },
  CLASS_TEST: {
    displayValue: "Class test",
    type: "beta",
    // platformIcon: "revel",
  },
};
export const SERP_SORT = {
  TITLE: {
    displayValue: "Title",
    type: "name",
  },
  AUTHOR: {
    displayValue: "Author",
    type: "familyName",
  },
  PUBLICATION_DATE: {
    displayValue: "Publication Date",
    type: "datePublished",
  },
};
export const SERP_SHOW = {
  SAMPLES: {
    displayValue: "Samples",
    type: "Sample",
  },
  COMING_SOON: {
    displayValue: "Coming Soon",
    type: "coming_soon",
  },
  AVAILABLE: {
    displayValue: "Available",
    type: "available",
  },
  INSTITUTION: {
    displayValue: "Institution",
    type: "Institution",
  },
};
export const SEARCH_PAGE_RESULT_COUNT = 24;
export const SEARCH_PAGE_RANGE = 4;
export const MAX_SUBJECTS = 4;
export const EXCHANGE_REF = {
  REVEL: "revel",
  CONSOLE: "console",
  ETEXT: "etext",
  COLLECTIONS: "collections",
};
export const ORG_CLASSIFICATION = {
  SCHOOL: "school",
  HIGHER_ED: "higher_ed",
  ANONYMOUS: "anonymous",
};
export const PRODUCT_AUDIENCE = {
  K12: "k12",
  HIGHER_ED: "highered",
  ALL: "all",
};
export const PRODUCT_TABS = {
  OVERVIEW: {
    KEY: "overview",
    VALUE: "Overview",
  },
  GETTING_STARTED: {
    KEY: "gettingStarted",
    VALUE: "Getting started",
  },
  FEATURES: {
    KEY: "features",
    VALUE: "Features",
  },
  CONTENTS: {
    KEY: "contents",
    VALUE: "Contents",
  },
  AUTHORS: {
    KEY: "authors",
    VALUE: "Author(s)",
  },
  MODULE: {
    KEY: "module",
    VALUE: "Modules",
  },
};
export const USER_ROLE = {
  K12_BUYER: "k12_buyer",
  HIGHER_ED_INSTRUCTOR: "highered_instructor",
  STUDENT: "student",
  ANONYMOUS: "anonymous",
};
export const K12_TYPE = {
  AVAILABLE_FOR_LICENSE: "availableForLicense",
};

export const REDIRECTIONS = {
  CONTACT_SAVVAS: "https://www.savvas.com/index.cfm?locator=PS2xE2",
};